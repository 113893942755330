export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID!!,
    clientSecret: process.env.REACT_APP_CLIENTSECRET!!,
    // clientSecret:
    //   "https://login.microsoftonline.com/HfY8Q~MgrFdPB2qO4vKiBJ_FRHVB996KmC~b~b5E/",
    authority: process.env.REACT_APP_AUTHORITY!!,
    redirectUri:
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_REDIRECT
        : (window as any)._env_.REACT_APP_REDIRECT!!,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["api://e24655db-ae39-4fa8-ab40-30781dddca62/keys"],
};

export const graphConfig = {
  graphMeEndpoint: "",
};
