import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import favicon from '../../../../assets/favicon.png';
import logo from '../../../../assets/logo.png';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';
import { Image } from 'react-bootstrap';

export interface NavLogoProps {

}

const NavLogo = (props: NavLogoProps) => {
  const configContext = useContext(ConfigContext);

  // @ts-ignore
  const { collapseMenu } = configContext.state;

  // @ts-ignore
  const { dispatch } = configContext;

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo">
        <Link to="/" className="b-brand">
          <div >
            <Image className="b-bg" style={{ background: "none" }} src={favicon}></Image>
          </div>
          <span className="b-title">U-Care Medical</span>
        </Link>
        <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}>
          <span />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
