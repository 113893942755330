import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { loginRequest } from '../../authConfig';


export interface AuthGuardProps {
  children: any;
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children } = props;
  //const { isLoggedIn } = useAuth();
  const isAuthenticated = useIsAuthenticated();
  // if (!isAuthenticated) {
  //   return <Redirect to="/auth/signin-1" />;
  // }

  //return <React.Fragment>{children}</React.Fragment>;
  return <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>{children}</MsalAuthenticationTemplate>;
};

export default AuthGuard;
