import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { msalConfig } from "../../../authConfig";
/**
 * Renders a button which, when selected, will open a popup for login
 */
const SignOutButton = () => {
    const { instance } = useMsal();

    const signOutClickHandler = async () => {
        const activeAccount = instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const accounts = instance.getAllAccounts();
        const logoutRequest: any = {
            account: activeAccount || accounts[0],
            postLogoutRedirectUri: `${msalConfig.auth.redirectUri}`,

        };
        await instance.logoutRedirect(logoutRequest);
    };

    return (
        <Link to="#" className="dropdown-item" onClick={signOutClickHandler}>
            <i className="feather icon-log-out" /> Logout
        </Link>
    );
};

export default SignOutButton;