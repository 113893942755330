import React from 'react';

export interface NavBadgeProps {
  items: any;
}
const NavBadge = (props: NavBadgeProps) => {
  const { items } = props;
  let navBadges = undefined;
  if (items.badge) {
    const badgeClass = ['label', 'pcoded-badge', items.badge.type];

    navBadges = <span className={badgeClass.join(' ')}>{items.badge.title}</span>;
  }

  return <React.Fragment>{navBadges}</React.Fragment>;
};

export default NavBadge;
