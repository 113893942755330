import React, { useContext, useEffect, useState } from 'react';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { ConfigContext } from '../../../../contexts/ConfigContext';

import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../../../assets/images/user/avatar-3.jpg';
import avatar4 from '../../../../assets/images/user/avatar-4.jpg';
import SignOutButton from '../../../../views/auth/signin/Logout';
import { useMsal } from '@azure/msal-react';
import { FaUserAlt } from "react-icons/fa";
import { useAppSelector } from '../../../../store/hooks';
import { stat } from 'fs';

export interface NavRightProps {

}
const NavRight = () => {
  const configContext = useContext(ConfigContext);
  const { accounts } = useMsal();
  const demoMode = useAppSelector(state => state.demoMode);

  // @ts-ignore
  const { rtlLayout } = configContext.state;



  const [listOpen, setListOpen] = useState(false);

  // const handleLogout = async () => {
  //   try {
  //     //handleClose();
  //     await logout();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <React.Fragment>

      <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto" >

        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="dropdown-menu-custom">
            <Dropdown.Toggle as={Link} variant="link" to="#">
              <i className="feather icon-bell icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="notification notification-scroll">

              {/* <div className="noti-footer">
                <Link to="#">show all</Link>
              </div> */}
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>



        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout}>
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <FaUserAlt />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="notification notification-scroll">

              {/* <div className="noti-footer">
                <Link to="#">show all</Link>
              </div> */}
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>



        {/* Messages list */}
        {/* <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown>
            <Dropdown.Toggle as={Link} variant="link" to="#" className="displayChatbox" onClick={() => setListOpen(true)}>
              <i className="icon feather icon-mail" />
            </Dropdown.Toggle>
          </Dropdown>
        </ListGroup.Item> */}
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              {/* <div className="pro-head">
                <img src={avatar1} className="img-radius" alt="User Profile" />
                <span>John Doe</span>
                <Link to="#" className="dud-logout" title="Logout">
                  <i className="feather icon-log-out" />
                </Link>
              </div> */}
              <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-settings" /> Settings
                  </Link>
                </ListGroup.Item>
                
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-mail" /> My Messages
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-lock" /> Lock Screen
                  </Link>
                </ListGroup.Item> */}
                <ListGroup.Item as="li" bsPrefix=" ">
                  <SignOutButton />
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>

    </React.Fragment>
  );
};

export default NavRight;
