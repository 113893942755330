export const adminMenuItems = {
  items: [
    {
      id: 'pages',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-pages',
      children: [
        {
          id: 'home',
          title: 'Home',
          type: 'item',
          url: '/',
          classes: 'nav-item',
          icon: 'feather icon-home'
        },
        {
          id: 'key-management',
          title: 'Key Management',
          type: 'item',
          url: '/keys-management',
          classes: 'nav-item',
          icon: 'feather key'
        },
      ]
    },

  ]
};

