import React from 'react';
export interface NavIconProps {
  items: any;
}
const NavIcon = (props: NavIconProps) => {
  const { items } = props;
  let navIcons;
  if (items.icon) {
    navIcons = (
      <span className="pcoded-micon">
        <i className={items.icon} />
      </span>
    );
  }

  return <React.Fragment>{navIcons}</React.Fragment>;
};

export default NavIcon;
