import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import "./web.config";
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { ConfigProvider } from "./contexts/ConfigContext";

import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./authConfig";
import axios from "axios";
import { acquireAccessToken } from "./api/acquireToken";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";


const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.request.use(
  async (config: any) => {
    const token = await acquireAccessToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    //response.headers.common.Authorization = `Bearer ${ msalResponse.accessToken }`;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});


ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <MsalProvider instance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </MsalProvider>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
