import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';


import AdminLayout from './layouts/AdminLayout/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';
import { Routes } from './types/Routes';
import { Circles } from 'react-loader-spinner';

export const renderRoutes = (routes: Routes[]) => (
  <Suspense fallback={<div className='d-flex justify-content-center'><Circles
    height="80"
    width="80"
    color="#04a9f5"
    ariaLabel="circles-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
  /></div>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;

        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props as any} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes[] = [
  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   path: '/auth/signin-1',
  //   component: lazy(() => import('./views/auth/signin/SignIn1'))
  // },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/NotFound404'))
  },
  {
    exact: true,
    path: '/maintenance/coming-soon',
    component: lazy(() => import('./views/maintenance/ComingSoon'))
  },
  {
    exact: true,
    path: '/maintenance/error',
    component: lazy(() => import('./views/maintenance/Error'))
  },
  {
    exact: true,
    path: '/maintenance/offline-ui',
    component: lazy(() => import('./views/maintenance/OfflineUI'))
  },
  // {
  //   exact: true,
  //   path: '/auth/signup-1',
  //   component: lazy(() => import('./views/auth/signup/SignUp1'))
  // },
  // {
  //   exact: true,
  //   path: '/auth/reset-password-1',
  //   component: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
  // },
  // {
  //   exact: true,
  //   path: '/auth/change-password',
  //   component: lazy(() => import('./views/auth/ChangePassword'))
  // },
  // {
  //   exact: true,
  //   path: '/auth/profile-settings',
  //   component: lazy(() => import('./views/auth/ProfileSettings'))
  // },

  {
    exact: true,
    layout: AdminLayout,
    guard: AuthGuard,
    path: '/keys-management',
    component: lazy(() => import("./views/pages/KeysManagement"))
  },

  {
    exact: true,
    layout: AdminLayout,
    guard: AuthGuard,
    path: '/',
    component: lazy(() => import("./views/pages/KeysManagement"))
  },

];

export default routes;
