import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import "./web.config";
//import { JWTProvider } from "./contexts/JWTContext";
//import { Auth0Provider } from "./contexts/Auth0Context";
import { ToastContainer, toast } from "react-toastify";
import routes, { renderRoutes } from "./routes";
import 'react-toastify/dist/ReactToastify.css';

//import NetInfo from "@react-native-community/netinfo";
import { BASENAME } from "./config/constant";
import { Provider } from "react-redux";
import store from "./store/store";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import useOnline from "./hooks/useOnline";
import { useAppSelector } from "./store/hooks";



const App = () => {
  const [online, setOnline] = useState(navigator.onLine);
  const [refetchInterval, setRefetchInterval] = useState(0);
  const [previousOnline, setPreviousOnline] = useState(navigator.onLine);




  useEffect(() => {
    if (!online)
      toast.error("Network connection problem", {
        autoClose: false,
        toastId: "offline"
      });
    if (online && !previousOnline) {
      toast.success("Network connection restablished", {
        toastId: "online"
      });

      setPreviousOnline(true);
    }
  }, [online, previousOnline]);

  useEffect(() => {
    window.addEventListener("online", () => {
      //console.log(navigator.onLine);
      setPreviousOnline(online);
      setOnline(navigator.onLine);
    });
    window.addEventListener("offline", () => {
      //console.log(navigator.onLine);
      setPreviousOnline(online);
      setOnline(navigator.onLine);
    });
  }, []);

  const toastNotification = () => {
    return <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />;
  };
  return (
    <React.Fragment>

      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Router basename="/">



        {renderRoutes(routes)}


        <ReactQueryDevtools />
      </Router>
    </React.Fragment>
  );
};

export default App;
